import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
  Button,
  Card as MaterialCard,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useLazyQuery } from "@apollo/client"
import { KeyboardDatePicker } from "@material-ui/pickers"
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined"

import { TourItem } from "./tour-item"
import { MinimalTour } from "../../../../api/graphql/queries/get-minimal-tours-with-region-id"
import moment from "moment"
import { useSelectedTour } from "../../../../context/selected-tour-context"
import { useAssociationFilter } from "../../../../context/AssociationFilterContext"
import { ContentContainer } from "../../../partials/layout/content/content-container"
import { executeCallbackOnEnter } from "../../../../utils/form"
import { UserService } from "../../../../services/user-service"
import { useDistrictFilter } from "../../../../context/DistrictFilterContext"
import { TourExportDialog } from "./tour-export-dialog"
import {
  GET_TOURS_AS_EXCEL_FILE_QUERY,
  GetToursAsExcelFileResult,
  GetToursAsExcelFileVariables,
} from "../../../../api/graphql/queries/get-tours-as-excel-file"
import { toast } from "react-toastify"
import { DownloadManagerContext } from "../../../partials/download-manager/download-manager-context"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: `calc(100% - ${theme.spacing(2)}px)`,
    display: "flex",
    flexDirection: "column",
  },
  subContainer: {
    padding: 0,
  },
  filterCard: {
    width: 270 - theme.spacing(1),
    padding: 15,
  },
  filterCardContainer: {
    padding: 0,
    marginBottom: theme.spacing(2),
    flex: "0 0 auto",
  },
  filterContainer: {
    width: 270,
  },
  filterContainerItems: {
    width: "100%",
  },
  snackBar: {
    marginTop: 10,
  },
  tourItemsContainer: {
    padding: 0,
    flex: "1 1 auto",
    overflowY: "auto",
    maxWidth: 300 - theme.spacing(1),
  },
}))

interface ITourSelectorProps {}

export const TourSelector: FunctionComponent<ITourSelectorProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [dateFromTemp, setDateFromTemp] = useState<moment.Moment | null>(moment().utc().startOf("day"))
  const [dateToTemp, setDateToTemp] = useState<moment.Moment | null>(null)
  const {
    selectedTour,
    setSelectedTour,
    tours,
    toursLoading,
    toursError,
    dateFrom,
    dateTo,
    setDateFrom,
    setDateTo,
  } = useSelectedTour()
  const { selectedAssociation } = useAssociationFilter()
  const { selectedDistrict } = useDistrictFilter()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const { setTriggerQuery } = useContext(DownloadManagerContext)

  useEffect(() => {
    if (tours.length > 0 && !toursLoading && !tours.find((entry) => entry.id === selectedTour?.id)) {
      setSelectedTour(tours[0])
    } else if (tours.length === 0 && selectedTour) {
      setSelectedTour(undefined)
    }
  }, [toursLoading, tours, selectedTour, setSelectedTour])

  const canExportManyTours: boolean = useMemo(() => {
    return !!tours && tours.length > 0
  }, [tours])

  const showError = () => {
    toast.error(t("export.unknown_error"))
  }

  const onRequestCompleted = (data: GetToursAsExcelFileResult) => {
    if (!data.getToursAsExcelFile) {
      showError()
    }
    setTriggerQuery(true)
  }

  const [getToursAsExcelFile, { loading: excelLoading }] = useLazyQuery<
    GetToursAsExcelFileResult,
    GetToursAsExcelFileVariables
  >(GET_TOURS_AS_EXCEL_FILE_QUERY, {
    onCompleted: onRequestCompleted,
    onError: showError,
    fetchPolicy: "no-cache",
  })

  const handleDownloadClick = useCallback(() => {
    if (tours) {
      const isOnlyOneVehicle = tours.every(
        (tour: MinimalTour) => tour.vehicle.licence_plate === tours[0].vehicle.licence_plate,
      )

      if (isOnlyOneVehicle) {
        const tourIds: string[] = tours.map((tour: MinimalTour) => tour.id.toString()) || ([] as MinimalTour[])

        getToursAsExcelFile({
          variables: {
            from: dateFrom,
            to: dateTo || moment.utc(),
            tourIds,
          },
        })
      } else {
        setIsDialogOpen(true)
      }
    }
    // else fail silently
  }, [setIsDialogOpen, tours, dateFrom, dateTo, getToursAsExcelFile])

  if (
    (UserService.hasAssociationFilter() && !selectedAssociation) ||
    (!UserService.hasAssociationFilter() && !selectedDistrict) ||
    toursLoading ||
    toursError
  ) {
    return null
  }

  const onFilterApplyClicked = () => {
    if ((!dateFromTemp || dateFromTemp.isValid()) && (!dateToTemp || dateToTemp.isValid())) {
      setDateFrom(dateFromTemp)
      setDateTo(dateToTemp)
      setSelectedTour(undefined)
    }
  }

  const onFilterRemoveClicked = () => {
    setDateFrom(moment().utc().startOf("day"))
    setDateTo(null)
    setDateFromTemp(moment().utc().startOf("day"))
    setDateToTemp(null)
    setSelectedTour(undefined)
  }

  return (
    <>
      <ContentContainer style={classes.container}>
        <Container className={classes.filterCardContainer}>
          <MaterialCard className={classes.filterCard}>
            <Grid container direction="column" className={classes.filterContainer} spacing={1}>
              <Grid item container direction="row-reverse">
                <Grid item>
                  <Tooltip title={t("tour_overview.tour_export_dialog.title")!}>
                    <span>
                      <IconButton
                        aria-label="export-multiple-tours"
                        color="primary"
                        disabled={!canExportManyTours}
                        onClick={handleDownloadClick}
                      >
                        {excelLoading && <CircularProgress size={18} />}
                        {!excelLoading && <GetAppOutlinedIcon />}
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog-from"
                  label={t("date_picker.from")}
                  format={t("date_format")}
                  value={dateFromTemp}
                  fullWidth
                  onChange={(date: moment.Moment | null, value: string | null | undefined) => {
                    if (value && date && date.isValid()) {
                      setDateFromTemp(moment(value, t("date_format")).startOf("day"))
                    } else {
                      setDateFromTemp(date)
                    }
                  }}
                  clearable
                  cancelLabel={t("date_picker.cancel")}
                  okLabel={t("date_picker.ok")}
                  clearLabel={t("date_picker.clear")}
                  onKeyDown={executeCallbackOnEnter(onFilterApplyClicked)}
                />
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog-to"
                  label={t("date_picker.to")}
                  format={t("date_format")}
                  minDate={dateFromTemp && dateFromTemp.clone()}
                  fullWidth
                  value={dateToTemp}
                  onChange={(date: moment.Moment | null, value: string | null | undefined) => {
                    if (value && date && date.isValid()) {
                      setDateToTemp(moment(value, t("date_format")).endOf("day"))
                    } else {
                      setDateToTemp(date as moment.Moment)
                    }
                  }}
                  clearable
                  cancelLabel={t("date_picker.cancel")}
                  okLabel={t("date_picker.ok")}
                  clearLabel={t("date_picker.clear")}
                  minDateMessage={t("errors.min_date")}
                  onKeyDown={executeCallbackOnEnter(onFilterApplyClicked)}
                />
              </Grid>
              <Grid item>
                <Button
                  className={classes.filterContainerItems}
                  variant="contained"
                  type="button"
                  color="primary"
                  onClick={onFilterApplyClicked}
                >
                  {t("collection_points.filter.apply_filter")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.filterContainerItems}
                  variant="contained"
                  type="button"
                  color="default"
                  onClick={onFilterRemoveClicked}
                >
                  {t("collection_points.filter.remove_filter")}
                </Button>
              </Grid>
            </Grid>
          </MaterialCard>
        </Container>
        {!!tours.length && (
          <Container className={classes.tourItemsContainer}>
            {tours.map((tour, index) => (
              <TourItem key={index} num={index + 1} item={tour as MinimalTour} />
            ))}
          </Container>
        )}
      </ContentContainer>

      <TourExportDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={() => setIsDialogOpen(false)}
        tours={tours || ([] as MinimalTour[])}
        dateFrom={dateFrom}
        dateTo={dateTo}
      />
    </>
  )
}
