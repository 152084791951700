import { Grid, IconButton, TableCell, TableRow, Tooltip } from "@material-ui/core"
import { OpenInNew } from "@material-ui/icons"
import moment from "moment"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { PATH, replaceUriParameter } from "../../../../../router/router"
import { DefectContainer } from "./defect-containers-table"

interface DefectContainersRowProps {
  container: DefectContainer
  materialNames: string[]
}

export const DefectContainersRow: FC<DefectContainersRowProps> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const {
    container: { place, street, collectionPointId, filllevel, materialName, sensorId, date, sequenceNumber, reason },
    materialNames,
  } = props

  return (
    <TableRow>
      <TableCell align="center">{sequenceNumber ?? "-"}</TableCell>

      <TableCell align="center">{date ? moment(date).format(t("date_format")) : "-"}</TableCell>

      <TableCell align="center">{sensorId}</TableCell>

      <TableCell align="center">{materialName}</TableCell>

      <TableCell align="center">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {street}
          </Grid>
          <Grid item xs={12}>
            {place}
          </Grid>
        </Grid>
      </TableCell>

      {materialNames.map((material,) => (
        <TableCell key={material} align="center" rowSpan={1}>
          {material === materialName ? filllevel : "-"}
        </TableCell>
      ))}

      <TableCell align="center">{reason ? t(`tour_overview.export_reasons.${reason}`) : "-"}</TableCell>

      <TableCell align="center">
        <Tooltip title={t("tour_overview.defect_containers.table.collection_point_tooltip") as string}>
          <IconButton
            onClick={() => {
              const url = replaceUriParameter(PATH.COLLECTIONPOINT.route, "collectionPointID", collectionPointId)
              history.push(url)
            }}
          >
            <OpenInNew color="primary" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}
