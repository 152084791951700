import { useLazyQuery } from "@apollo/client"
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
  GetContainerExcelResult,
  GetContainerExcelVariables,
  GET_CONTAINER_EXCEL_WITH_REGION_ID_QUERY,
} from "../../../../../api/graphql/queries/get-container-excel-with-region-id"
import i18next from "i18next"
import { toast } from "react-toastify"
import { UserService } from "../../../../../services/user-service"
import moment, { Moment } from "moment"
import { useCollectionPointsFilter } from "../../../../../context/CollectionPointsFilterContext"
import { useAssociationFilter } from "../../../../../context/AssociationFilterContext"
import { useDistrictFilter } from "../../../../../context/DistrictFilterContext"
import { DownloadManagerContext } from "../../../../partials/download-manager/download-manager-context"
import { useSelectedRegion } from "../../../../../hooks/use-selected-region"

interface ICollectionPointsExportContext {
  exportLoading: boolean
  dialogOpen: boolean
  setDialogOpen: (value: boolean) => void
  onExportConfirmed: (startDate: Moment, endDate: Moment, useFilter: boolean) => void
  startDate: Moment
  endDate: Moment
  setStartDate: (value: Moment) => void
  setEndDate: (value: Moment) => void
  resetValues: () => void
  forecastEnabled: boolean
  useFilter: boolean
  setUseFilter: (value: boolean) => void
}

interface ICollectionPointExportProviderProps {}

export const CollectionPointsExportContext = createContext<ICollectionPointsExportContext>({
  exportLoading: false,
  dialogOpen: false,
  setDialogOpen: () => null,
  onExportConfirmed: () => null,
  startDate: moment(),
  endDate: moment(),
  setStartDate: () => null,
  setEndDate: () => null,
  resetValues: () => null,
  forecastEnabled: false,
  useFilter: false,
  setUseFilter: () => null,
})

export const CollectionPointsExportProvider = (props: PropsWithChildren<ICollectionPointExportProviderProps>) => {
  const { filter } = useCollectionPointsFilter()
  const { selectedAssociation } = useAssociationFilter()
  const { selectedDistrict } = useDistrictFilter()
  const { variables } = useSelectedRegion()
  const { setTriggerQuery } = useContext(DownloadManagerContext)

  // admins always see forecast; other roles only if it's enabled for the region
  const forecastEnabled = useMemo(
    () =>
      UserService.isAdmin() ||
      (UserService.hasAssociationFilter() ? selectedAssociation?.forecastEnabled : selectedDistrict?.forecastEnabled) ||
      false,
    [selectedAssociation, selectedDistrict],
  )

  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Moment>(moment().subtract(14, "day").startOf("day"))
  const [endDate, setEndDate] = useState<Moment>(
    forecastEnabled ? moment().add(14, "day").endOf("day") : moment().endOf("day"),
  )

  const [useFilter, setUseFilter] = useState<boolean>(true)

  const showError = useCallback(() => {
    toast.error(i18next.t("export.unknown_error"))
  }, [])

  const resetValues = useCallback(() => {
    setStartDate(moment().subtract(14, "day").startOf("day"))
    setEndDate(forecastEnabled ? moment().add(14, "day").endOf("day") : moment().endOf("day"))
    setUseFilter(true)
  }, [forecastEnabled])

  const onRequestCompleted = useCallback(
    (data: GetContainerExcelResult) => {
      setDialogOpen(false)
      resetValues()
      if (!data?.getContainerExcelWithRegionId) {
        showError()
      }
      setTriggerQuery(true)
    },
    [showError, resetValues, setTriggerQuery],
  )

  const [getContainerExcel, { loading: exportLoading }] = useLazyQuery<
    GetContainerExcelResult,
    GetContainerExcelVariables
  >(GET_CONTAINER_EXCEL_WITH_REGION_ID_QUERY, {
    onCompleted: onRequestCompleted,
    onError: showError,
    fetchPolicy: "no-cache",
  })

  const onExportConfirmed = useCallback(
    (startDate: Moment, endDate: Moment) =>
      getContainerExcel({
        variables: {
          id: String(variables.id),
          type: variables.type,
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
          filter: useFilter ? filter : undefined,
        },
      }),
    [filter, getContainerExcel, variables, useFilter],
  )

  useEffect(() => {
    setEndDate(forecastEnabled ? moment().add(14, "day").endOf("day") : moment().endOf("day"))
  }, [forecastEnabled])

  return (
    <CollectionPointsExportContext.Provider
      value={{
        exportLoading,
        dialogOpen,
        setDialogOpen,
        onExportConfirmed,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        resetValues,
        forecastEnabled,
        useFilter,
        setUseFilter,
      }}
    >
      {props.children}
    </CollectionPointsExportContext.Provider>
  )
}
