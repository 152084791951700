import React, { FunctionComponent, useState, Fragment, useEffect, useContext } from "react"
import { RouteComponentProps } from "react-router"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { Grid, Theme, makeStyles, CircularProgress } from "@material-ui/core"

import { CustomSnackBarContent } from "../../partials/wrapper/CustomSnackBarContent"
import { PageHeading } from "../../partials/layout/heading/page-heading"
import { ContentContainer } from "../../partials/layout/content/content-container"
import { Card } from "../../partials/layout/card/model/card"
import { MapsHelper } from "../../partials/maps/maps-helper"
import { CardsContainer } from "../../partials/layout/card/cards-container"
import { CollectionPointsTable } from "../../partials/layout/table/collection-points-table"
import {
  MinimalRegionStatsResult,
  GET_MINIMAL_REGION_STATS_QUERY,
  GetMinimalRegionStatsVariables,
} from "../../../api/graphql/queries/get-minimal-region-stats"
import {
  GET_COLLECTIONPOINT_WITH_REGION_ID_QUERY,
  GetCollectionPointsWithRegionIDResult,
  GetCollectionPointsWithRegionIDVariables,
} from "../../../api/graphql/queries/get-collection-points-with-region-id"
import { CustomMap, IMarker, ICenterOption } from "../../partials/maps/custom-map"
import { AssociationsResult, GETASSOCIATIONS_QUERY } from "../../../api/graphql/queries/get-associations"
import { CardInfoHelper } from "../../../utils/CardInfoHelper"
import { EmptyingStats } from "../../../api/graphql/queries/get-collection-point-stats-with-id"
import { useAssociationFilter } from "../../../context/AssociationFilterContext"
import { defaultLocation } from "../../../utils/map"
import { CollectionPointExportButton } from "./partials/collection-point-export/collection-points-export-button"
import { CollectionPointsExportProvider } from "./partials/collection-point-export/collection-points-export-context"
import { CollectionPointsFilter } from "./partials/collection-points-filter"
import { UserService } from "../../../services/user-service"
import { GETMATERIALS_QUERY, MaterialsResult } from "../../../api/graphql/queries/get-materials"
import { useCollectionPointsFilter } from "../../../context/CollectionPointsFilterContext"
import {
  GET_COLLECTIONPOINTS_FOR_MAP_QUERY,
  GetCollectionPointsForMapVariables,
  GetCollectionPointsForMapResult,
} from "../../../api/graphql/queries/get-collection-points-for-map"
import lodash from "lodash"
import { UserContext } from "../../../context/user-context"
import moment from "moment"
import { useSelectedRegion } from "../../../hooks/use-selected-region"

const useStyles = makeStyles((theme: Theme) => ({
  snackBar: {
    marginTop: theme.spacing(1),
  },
  headingContainer: {
    marginBottom: theme.spacing(1),
  },
  notificationsContainer: {
    flex: 1,
  },
  statsWrapper: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}))

interface ICollectionPointsPageProps extends RouteComponentProps {}

export const CollectionPointsPage: FunctionComponent<ICollectionPointsPageProps> = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { selectedAssociation } = useAssociationFilter()
  const { skip, variables } = useSelectedRegion()
  const [selectedCollectionPoint, setSelectedCollectionPoint] = useState<number>()
  const [collectionPointStatsCards, setCollectionPointStatsCards] = useState<Card[]>([])
  const [regionStatsCards, setRegionStatsCards] = useState<Card[]>([])
  const [markers, setMarkers] = useState<IMarker[]>([])
  const { filter, tableProps, setTableProps, referenceDate } = useCollectionPointsFilter()
  const { problemSensorInterval } = useContext(UserContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const tableRef = React.useRef(null)

  const onCollectionPointsForMapQueryCompleted = () => {
    if (!lodash.isNil(collectionPointsData?.getCollectionPointsWithRegionID)) {
      setMarkers(
        MapsHelper.getMarkersWithFilllevels(
          collectionPointsData?.getCollectionPointsWithRegionID.entries || [],
          problemSensorInterval,
        ),
      )
    }
  }

  const { data: associationsData, error: associationError } = useQuery<AssociationsResult>(GETASSOCIATIONS_QUERY, {
    skip: !UserService.hasAssociationFilter(),
  })
  const { data: materialsData } = useQuery<MaterialsResult>(GETMATERIALS_QUERY)

  const { data: collectionPointsData, loading: collectionPointsLoading } = useQuery<
    GetCollectionPointsForMapResult,
    GetCollectionPointsForMapVariables
  >(GET_COLLECTIONPOINTS_FOR_MAP_QUERY, {
    variables: {
      id: Number(variables.id),
      type: variables.type,
      page: 0,
      pagesize: 1000000,
      filter,
      date: referenceDate.diff(moment(), "days") > 0 ? referenceDate.toDate() : null,
    },
    onCompleted: onCollectionPointsForMapQueryCompleted,
    skip,
  })

  const { data: regionStatsData, loading: regionStatsLoading } = useQuery<
    MinimalRegionStatsResult,
    GetMinimalRegionStatsVariables
  >(GET_MINIMAL_REGION_STATS_QUERY, {
    variables: {
      id: Number(variables.id),
      type: variables.type,
      filter,
    },
    skip: collectionPointsData === undefined || collectionPointsLoading,
    onCompleted: () => onRegionStatsQueryCompleted(),
  })

  const { data: collectionPointStatsData, loading: collectionPointStatsLoading } = useQuery<
    GetCollectionPointsWithRegionIDResult,
    GetCollectionPointsWithRegionIDVariables
  >(GET_COLLECTIONPOINT_WITH_REGION_ID_QUERY, {
    variables: {
      id: Number(variables.id),
      type: variables.type,
      page: tableProps.page,
      pagesize: tableProps.pagesize,
      order: tableProps.order,
      filter,
      date: referenceDate.diff(moment(), "days") > 0 ? referenceDate.toDate() : null,
    },
    skip: regionStatsData === undefined || regionStatsLoading,
    onCompleted: () => onCollectionPointsWithRegionIdQueryCompleted(),
  })

  if (associationError) {
    return <CustomSnackBarContent className={classes.snackBar} variant="error" message={t("errors.generic")} />
  }

  const regionStats =
    regionStatsData && regionStatsData.getMinimalRegionStats ? regionStatsData.getMinimalRegionStats : undefined

  const collectionPointStats = collectionPointStatsData?.getCollectionPointsWithRegionID
    ? collectionPointStatsData.getCollectionPointsWithRegionID
    : undefined

  const onCollectionPointsWithRegionIdQueryCompleted = () => {
    if (collectionPointStats) {
      setCollectionPointStatsCards([CardInfoHelper.getCountOfCollectionPointsCard(collectionPointStats.totalEntries)])
    }
  }

  const onRegionStatsQueryCompleted = () => {
    if (regionStats) {
      setRegionStatsCards(
        [
          CardInfoHelper.getContainerInfoGroupCard(regionStats.container_group_infos),
          regionStats.last_emptying && CardInfoHelper.getLastEmptyingCard(regionStats.last_emptying as EmptyingStats),
          regionStats.next_emptying && CardInfoHelper.getNextEmptyingCard(regionStats.next_emptying as EmptyingStats),
          regionStats.collection_amount &&
            CardInfoHelper.getLast30DaysCollectedAmountCard(regionStats.collection_amount),
        ].filter((card) => !!card) as Card[],
      )
    }
  }

  const getAssociatonCoordinates = (): ICenterOption => {
    const association = associationsData!.getAssociations.find(
      (association) => association.id === selectedAssociation?.id,
    )

    if (association) {
      return {
        lat: association.latitude || defaultLocation.latitude,
        lng: association.longitude || defaultLocation.longitude,
        id: 0,
      }
    }
    return {
      lat: 0,
      lng: 0,
      id: 0,
    }
  }

  const onFilterUpdated = () => {
    setSelectedCollectionPoint(undefined)
    setTableProps({ ...tableProps, page: 0 })
  }

  return (
    <Fragment>
      <Grid container justify="space-between" className={classes.headingContainer}>
        <Grid item>
          <PageHeading>{t("collection_points.heading")}</PageHeading>
        </Grid>
        <Grid item>
          <CollectionPointsExportProvider>
            <CollectionPointExportButton />
          </CollectionPointsExportProvider>
        </Grid>
      </Grid>
      <CollectionPointsFilter onFilterUpdated={onFilterUpdated} variant="overview" />
      {collectionPointsLoading && (
        <ContentContainer>
          <Grid container justify="center" alignItems="center">
            <CircularProgress />
          </Grid>
        </ContentContainer>
      )}
      {!collectionPointsLoading && markers.length > 0 && (
        <Fragment>
          {/* Town Stats */}
          <CardsContainer
            justify="center"
            cards={[...collectionPointStatsCards, ...regionStatsCards]}
            loading={regionStatsLoading}
          />
          {/* Map */}
          <ContentContainer>
            <CustomMap
              height="400px"
              center={
                selectedCollectionPoint ? markers.find((marker) => marker.id === selectedCollectionPoint) : undefined
              }
              onMarkerClicked={(id: number) => {
                setSelectedCollectionPoint(id)
              }}
              markers={markers}
              selectedMarkerId={selectedCollectionPoint}
              referenceDate={referenceDate.diff(moment(), "days") > 0 ? referenceDate.toDate() : undefined}
              placeSearch
            />
          </ContentContainer>
          {/* Table */}
          <ContentContainer>
            <CollectionPointsTable
              ref={tableRef}
              data={collectionPointStatsData ? collectionPointStatsData.getCollectionPointsWithRegionID.entries : []}
              selectedRow={selectedCollectionPoint}
              loading={collectionPointStatsLoading}
              rowNumber={collectionPointStatsData?.getCollectionPointsWithRegionID.totalEntries || 0}
              onRowClicked={(id: number) => {
                if (id === selectedCollectionPoint) {
                  setSelectedCollectionPoint(undefined)
                } else {
                  setSelectedCollectionPoint(id)
                }
              }}
              onPaginationChanged={() => setSelectedCollectionPoint(undefined)}
              onSortingChanged={() => setSelectedCollectionPoint(undefined)}
              materials={materialsData?.getMaterials || []}
            />
          </ContentContainer>
        </Fragment>
      )}
      {!collectionPointsLoading && !collectionPointStatsLoading && !regionStatsLoading && markers.length === 0 && (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomSnackBarContent
              className={classes.snackBar}
              variant="info"
              message={t(`errors.no_${UserService.hasAssociationFilter() ? "association" : "district"}_dashboard_data`)}
            />
          </Grid>
          {associationsData && (
            <Grid item>
              <CustomMap height="400px" center={getAssociatonCoordinates()} placeSearch zoom={12} />
            </Grid>
          )}
        </Grid>
      )}
    </Fragment>
  )
}
