import { Chip, Grid, makeStyles, Theme } from "@material-ui/core"
import { Info, WarningRounded } from "@material-ui/icons"
import classNames from "classnames"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ExportReason, StopStatus } from "../../../../api/graphql/graphql-global-types"
import { StopInfo } from "../../../../api/graphql/queries/get-tour-stats-with-id"

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    paddingLeft: theme.spacing(1),
  },
  skippedChip: {
    backgroundColor: "#fae884",
    color: "#b58900",
  },
  driverAddedChip: {
    backgroundColor: "#e0e0e0",
    color: "#424242",
  },
  skipIcon: {
    color: "#b58900",
  },
}))

interface Props {
  stopInfo: StopInfo
}

export const StopTableRowHintCell: React.FC<Props> = (props) => {
  const { stopInfo } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const hintItems = useMemo(() => {
    const items: JSX.Element[] = []

    if (stopInfo.status === StopStatus.CANCELED) {
      items.push(
        <Chip
          icon={<WarningRounded className={classes.skipIcon} fontSize="small" />}
          label={t(`tour_overview.table.hints.cancelled`)}
          className={classNames(classes.chip, classes.skippedChip)}
        />,
      )
    }

    if (stopInfo.reason === ExportReason.MANUALLY_INSERTED_BY_DRIVER) {
      items.push(
        <Chip
          icon={<Info fontSize="small" />}
          label={t(`tour_overview.table.hints.driver_inserted`)}
          className={classNames(classes.chip, classes.driverAddedChip)}
        />,
      )
    }

    return items
  }, [stopInfo, t, classes])

  return (
    <>
      {hintItems.length > 0 && (
        <Grid container spacing={1} alignItems="center" direction="column">
          {hintItems.map((item, idx) => (
            <Grid item key={`stopinfo_${stopInfo.id}_hint_${idx}`}>
              {item}
            </Grid>
          ))}
        </Grid>
      )}
      {hintItems.length === 0 && "-"}
    </>
  )
}
