import React, { useCallback, useMemo, useState } from "react"
import { Button, Grid, IconButton, Tooltip, makeStyles } from "@material-ui/core"
import { PageHeading } from "../../../partials/layout/heading/page-heading"
import { useSelectedTour } from "../../../../context/selected-tour-context"
import { useTourOverview } from "../context/tour-overview-context"
import { useTranslation } from "react-i18next"
import { UserService } from "../../../../services/user-service"
import { UserRole } from "../../../../api/graphql/graphql-global-types"
import { CalendarToday, Delete, GetAppOutlined, ScheduleSharp, Warning } from "@material-ui/icons"

import { UserGroups } from "../../../../models/user-groups"
import { DefectContainersDialog } from "./defect-containers-dialog/defect-containers-dialog"
import { DeleteDialog } from "../../../partials/layout/dialog/delete-dialog"
import { toast } from "react-toastify"
import { useRefetch } from "../../../../context/refetch-context"
import { RescheduleTourDialog } from "./reschedule-tour-dialog"
import moment from "moment"

interface TourHeaderProps {}

const useStyles = makeStyles((theme) => ({
  progressIconContainer: {
    display: "flex",
  },
}))

export const TourHeader: React.FC = (_props: TourHeaderProps) => {
  const [isShowDefectContainersModalOpen, setIsShowDefectContainersModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false)

  const { setSelectedTour } = useSelectedTour()
  const { tourStatsData, getTourAsExcelFile, excelLoading, deleteTour } = useTourOverview()
  const { setNeedToRefetch } = useRefetch()

  const { t } = useTranslation()

  const classes = useStyles()

  const onDeleteTour = useCallback(async () => {
    const result = await deleteTour({
      variables: {
        tour_id: String(tourStatsData?.getTourStatsWithId?.tour?.id),
      },
    })
    if (result.data?.deleteTour?.error === "false") {
      toast.info(t("tour_overview.tour_deleted"))
      setSelectedTour(undefined)
      setNeedToRefetch(true)
      setIsDeleteModalOpen(false)
    } else {
      toast.error(t("tour_overview.could_not_delete_tour"))
    }
  }, [deleteTour, setSelectedTour, setNeedToRefetch, tourStatsData, t])

  const canDeleteTour = useMemo(
    () => UserService.isInUserGroup(UserGroups.TOUR_MANAGEMENT) && !!tourStatsData?.getTourStatsWithId?.tour?.deletable,
    [tourStatsData],
  )

  const tour = useMemo(() => tourStatsData?.getTourStatsWithId?.tour, [tourStatsData])

  if (!tour) {
    return null
  }

  return (
    <>
      <DefectContainersDialog
        open={isShowDefectContainersModalOpen}
        onClose={() => setIsShowDefectContainersModalOpen(false)}
        tourParametersId={String(tour.tour_parameters_id)}
      />
      <DeleteDialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        content={t("tour_overview.confirm_delete_text")}
        onDelete={() => onDeleteTour()}
      />
      <RescheduleTourDialog
        open={isRescheduleModalOpen}
        onClose={() => setIsRescheduleModalOpen(false)}
        tourId={tour.id}
        currentDate={tour.date}
      />
      <Grid container direction="row" justify="space-between" alignItems="flex-start">
        <Grid item>
          <Grid item container justify="flex-start" alignItems="center">
            <Grid item>
              <PageHeading>
                {`${tour.manuallyCreated ? `${t("tour_overview.heading_prefix_manual")} ` : ""}${t(
                  "tour_overview.heading",
                )} ${moment(tour.date).format(t("date_format"))}`}
              </PageHeading>
            </Grid>

            {UserService.getRole() === UserRole.SUPER_ADMIN && (
              <Button onClick={() => setIsShowDefectContainersModalOpen(true)}>
                <Warning color="primary" />
                {`(${tour.countOfDefectContainers ?? "-"})`}
              </Button>
            )}

            {tour.manuallyCreated && !tour.finished && (
              <Grid item className={classes.progressIconContainer}>
                <Tooltip placement="top" title={t("tour_overview.in_progress") as string}>
                  <ScheduleSharp color="primary" />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={6} justify="flex-end">
          <Grid item>
            <IconButton
              color="primary"
              disabled={excelLoading}
              onClick={() =>
                getTourAsExcelFile({
                  variables: {
                    tour_id: String(tour.id),
                  },
                })
              }
            >
              <GetAppOutlined />
            </IconButton>
          </Grid>
          {canDeleteTour && (
            <>
              <Grid item>
                <Tooltip placement="top" title={t("tour_overview.reschedule") as string}>
                  <IconButton color="primary" onClick={() => setIsRescheduleModalOpen(true)} aria-label="reschedule">
                    <CalendarToday />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Grid item>
                  <IconButton onClick={() => setIsDeleteModalOpen(true)} aria-label="delete">
                    <Delete color="error" />
                  </IconButton>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}
