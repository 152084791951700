import React, { FunctionComponent, Fragment, useEffect, useState, useMemo, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { ContentContainer } from "../../../partials/layout/content/content-container"
import { TourSelector } from "./tour-selector"
import {
  Grid,
  Card as MaterialCard,
  makeStyles,
  Theme,
  CircularProgress,
  IconButton,
  Paper,
  CardContent,
  Typography,
} from "@material-ui/core"
import { CardInfoHelper } from "../../../../utils/CardInfoHelper"
import { StopsTable } from "../../../partials/layout/table/stops-table"
import { CustomSnackBarContent } from "../../../partials/wrapper/CustomSnackBarContent"
import { useSelectedTour } from "../../../../context/selected-tour-context"
import lodash from "lodash"
import { RegionSelect } from "../../../partials/region-select/region-select"
import { UserService } from "../../../../services/user-service"
import { Edit } from "@material-ui/icons"
import { UserGroups } from "../../../../models/user-groups"
import { EditTourModal } from "./edit-tour-modal"
import { useTourOverview } from "../context/tour-overview-context"
import { TourOverviewMap } from "./tour-overview-map/tour-overview-map"
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined"
import QueryBuilderOutlinedIcon from "@material-ui/icons/QueryBuilderOutlined"
import TodayOutlinedIcon from "@material-ui/icons/TodayOutlined"
import { TourDetail } from "./tour-detail"
import { TourHeader } from "./tour-header"
import moment from "moment"

const useStyles = makeStyles((theme: Theme) => ({
  snackBar: {
    marginTop: 10,
  },
  contentContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    overflowY: "auto",
    width: "100%",
    height: "100%",
    flex: "1",
  },
  container: {
    height: "calc(100vh - 56px)", // 56px equals all paddings and margins
    display: "flex",
    flexDirection: "column",
  },
  associationFilterCard: {
    padding: `0 ${theme.spacing(1)}px`,
    flex: "0 0 auto",
    marginBottom: theme.spacing(1),
  },
  tourDataContainer: {
    padding: `0 ${theme.spacing(1)}px`,
    flex: "1 1 auto",
    overflowY: "auto",
    height: "100%",
  },
  selectorContainer: {
    height: "100%",
  },
  button: {
    width: 140,
  },
  progressIconContainer: {
    display: "flex",
  },
  bold12px: {
    fontWeight: "bold",
    fontSize: "12px",
  },
  bold14px: {
    fontWeight: "bold",
    fontSize: "14px",
  },
  vehicle: {
    fontSize: "10px",
    marginTop: "-5px",
  },
  duration: {
    fontSize: "12px",
  },
  paper: {
    borderRadius: "16px",
    height: "60px",
    padding: "2px",
  },
  icon: {
    fontSize: "10",
    marginTop: "3px",
  },
  tableTitle: {
    fontSize: "20px",
    marginTop: "10px",
    fontWeight: "bold",
    color: "grey",
  },
  tourDetailContainer: {
    height: "100%",
  },
}))

interface ITourOverviewProps {}

export const TourOverview: FunctionComponent<ITourOverviewProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles(props)

  const { selectedTour, tours } = useSelectedTour()
  const [isEditTourModalOpen, setIsEditTourModalOpen] = useState(false)

  const {
    selectedStopId,
    setSelectedStopId,
    tourStatsData,
    tourStatsError,
    tourStatsLoading,
    stopInfos,
  } = useTourOverview()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const resetSelectedStopId = useCallback(() => {
    setSelectedStopId(undefined)
  }, [setSelectedStopId])

  useEffect(() => {
    resetSelectedStopId()
  }, [resetSelectedStopId])

  const actualDuration = useMemo(
    () =>
      tourStatsData?.getTourStatsWithId?.tour?.actual_duration
        ? CardInfoHelper.getFormattedTourDuration(tourStatsData.getTourStatsWithId.tour.actual_duration)
        : null,
    [tourStatsData],
  )

  const plannedDuration = useMemo(
    () =>
      tourStatsData?.getTourStatsWithId?.tour?.duration
        ? CardInfoHelper.getFormattedTourDuration(tourStatsData.getTourStatsWithId.tour.duration)
        : null,
    [tourStatsData],
  )

  return (
    <Fragment>
      <EditTourModal
        open={!lodash.isEmpty(tourStatsData?.getTourStatsWithId?.stop_infos) && isEditTourModalOpen}
        onClose={() => setIsEditTourModalOpen(false)}
        stopInfos={tourStatsData?.getTourStatsWithId?.stop_infos || []}
        districtId={Number(tourStatsData?.getTourStatsWithId?.tour?.district_id)}
        date={tourStatsData?.getTourStatsWithId?.tour?.date}
        tourId={String(selectedTour?.id)}
      />
      <Grid className={classes.container}>
        {/* Association Select */}
        <ContentContainer style={classes.associationFilterCard}>
          <MaterialCard style={{ padding: 15 }}>
            <RegionSelect />
          </MaterialCard>
        </ContentContainer>

        <Grid className={classes.tourDataContainer} container direction="row">
          <Grid item className={classes.selectorContainer}>
            <TourSelector />
          </Grid>
          <Grid className={classes.contentContainer} item>
            <Grid container direction="column" spacing={2}>
              {tourStatsLoading && (
                <Grid container item justify="center" alignItems="center">
                  <CircularProgress />
                </Grid>
              )}
              {tourStatsData && !tourStatsError && !tourStatsLoading && selectedTour && (
                <Fragment>
                  <TourHeader />
                  <Grid container justify="flex-start" alignItems="stretch" spacing={1}>
                    <Grid item xs={7}>
                      <TourOverviewMap />
                    </Grid>

                    <Grid item xs={5}>
                      <Grid container spacing={1} className={classes.tourDetailContainer}>
                        {tourStatsData.getTourStatsWithId?.emptying_stats?.licence_plate && (
                          <Grid item xs={6}>
                            <Paper variant="outlined" className={classes.paper}>
                              <CardContent>
                                <Grid container direction="row" spacing={1} alignItems="center">
                                  <Grid item>
                                    <LocalShippingOutlinedIcon className={classes.icon} />
                                  </Grid>
                                  <Grid container item xs direction="column" justify="center">
                                    <Grid item>
                                      <Typography variant="subtitle1" className={classes.bold14px}>
                                        {tourStatsData.getTourStatsWithId?.emptying_stats?.licence_plate}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography className={classes.vehicle}> {t("Truck")} </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Paper>
                          </Grid>
                        )}

                        <Grid item xs={6}>
                          <Paper variant="outlined" className={classes.paper}>
                            <CardContent>
                              <Grid container direction="row" spacing={1} alignItems="center">
                                <Grid item>
                                  <TodayOutlinedIcon className={classes.icon} />
                                </Grid>
                                <Grid container item xs direction="column">
                                  <Grid item>
                                    <Typography variant="subtitle1" className={classes.bold12px}>
                                      {` ${moment(selectedTour.date).format(t("date_format"))}`}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Paper>
                        </Grid>

                        <Grid item xs={12}>
                          {plannedDuration && (
                            <Paper variant="outlined" className={classes.paper}>
                              <CardContent>
                                <Grid container direction="row" spacing={1} alignItems="center">
                                  <Grid item>
                                    <QueryBuilderOutlinedIcon className={classes.icon} />
                                  </Grid>
                                  <Grid item xs={4} container alignItems="center">
                                    <Typography variant="subtitle1" className={classes.bold14px}>
                                      {plannedDuration}
                                    </Typography>
                                    <Typography className={classes.duration}>
                                      &nbsp;{t("tour_overview.planned_duration")}
                                    </Typography>
                                  </Grid>
                                  <Grid item className={classes.bold14px}>
                                    |
                                  </Grid>
                                  <Grid item xs container alignItems="center">
                                    <Typography variant="subtitle1" className={classes.bold14px}>
                                      {actualDuration ? actualDuration : "--"}
                                    </Typography>
                                    <Typography className={classes.duration}>
                                      &nbsp;{t("tour_overview.actual_duration")}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Paper>
                          )}
                        </Grid>
                        <Grid item xs={12} className={classes.tourDetailContainer}>
                          <TourDetail />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container justify="flex-start">
                    <Grid item>
                      <Typography className={classes.tableTitle}>{t("tour_overview.collection_points")}</Typography>
                    </Grid>
                    {UserService.isInUserGroup(UserGroups.TOUR_MANAGEMENT) && (
                      <Grid item>
                        <IconButton onClick={() => setIsEditTourModalOpen(true)}>
                          <Edit color="primary" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item>
                    <StopsTable
                      tourId={selectedTour?.id}
                      isInEditMode={false}
                      onRowClicked={(id: number) => {
                        if (id === selectedStopId) {
                          setSelectedStopId(undefined)
                        } else {
                          setSelectedStopId(id)
                        }
                      }}
                      stopInfos={stopInfos}
                      selectedStopId={selectedStopId}
                    />
                  </Grid>
                </Fragment>
              )}

              {tours && tours.length === 0 && (
                <CustomSnackBarContent
                  className={classes.snackBar}
                  variant="info"
                  message={t(
                    `errors.no_${UserService.hasAssociationFilter() ? "association" : "district"}_tours_found`,
                  )}
                />
              )}

              {tours && tours.length > 0 && selectedTour === undefined && (
                <CustomSnackBarContent
                  className={classes.snackBar}
                  variant="info"
                  message={t("tour_selector.choose")}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}
