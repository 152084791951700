import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react"
import { Theme, makeStyles, Grid, Backdrop, CircularProgress, Typography, Button } from "@material-ui/core"
import { Trans, useTranslation } from "react-i18next"
import { Dropzone } from "../../../../partials/layout/dropzone/dropzone"
import { useUploadCollectionPointsContext } from "./upload-collection-points-context"
import { InfoDialog } from "../../../../partials/layout/dialog/info-dialog"
import { UploadValidationErrorType } from "../../../../../api/graphql/graphql-global-types"
import { UploadCollectionPointsTable } from "./upload-collection-points-table"
import { UserService } from "../../../../../services/user-service"
import { useHistory } from "react-router-dom"
import { useSelectedRegion } from "../../../../../hooks/use-selected-region"

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    height: 300,
    justifyContent: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
  },
  templateLabel: {
    color: "#979797",
    fontSize: "14px",
    userSelect: "none",
  },
  templateLink: {
    textDecoration: "underline",
    fontWeight: "bold",
    cursor: "pointer",
  },
  templateLinkLoading: {
    cursor: "progress",
  },
}))

interface IUploadCollectionPointsPageProps {}

export const UploadCollectionPointsPage: FunctionComponent<IUploadCollectionPointsPageProps> = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [confirmedErrors, setConfirmedErrors] = useState<boolean>(false)
  const history = useHistory()

  const { regionName } = useSelectedRegion()

  const {
    file,
    setFile,
    validateFile,
    validationLoading,
    uploadLoading,
    validationErrors,
    collectionPoints,
    downloadTemplateFile,
    downloadTemplateFileLoading,
  } = useUploadCollectionPointsContext()
  const files = useMemo(() => (file === null ? [] : [file]), [file])

  const onFileDropped = useCallback(
    (files: File[]) => {
      const droppedFile = files[0]
      if (droppedFile) {
        validateFile(droppedFile)
      }
      setFile(droppedFile)
    },
    [setFile, validateFile],
  )

  useEffect(() => {
    setConfirmedErrors(false)
  }, [validationErrors])

  const getErrorMessage = useCallback(
    (errorType: UploadValidationErrorType) => {
      switch (errorType) {
        case UploadValidationErrorType.missing_header_row:
        case UploadValidationErrorType.duplicate_header:
        case UploadValidationErrorType.missing_header:
        case UploadValidationErrorType.missing_required_fields:
          return t("collection_point_administration.upload.errors.invalid_format")
        case UploadValidationErrorType.invalid_materials:
        case UploadValidationErrorType.invalid_container_types:
        case UploadValidationErrorType.invalid_discharge_types:
        case UploadValidationErrorType.invalid_container_type_info:
          return t("collection_point_administration.upload.errors.invalid_container_type_info")
        case UploadValidationErrorType.invalid_accessible_times:
          return t("collection_point_administration.upload.errors.invalid_accessible_times")
        case UploadValidationErrorType.invalid_coordinates:
          return t("collection_point_administration.upload.errors.invalid_coordinates")
        case UploadValidationErrorType.connected_region_not_found:
          return UserService.hasAssociationFilter()
            ? t("collection_point_administration.upload.errors.connected_district_not_found")
            : t("collection_point_administration.upload.errors.connected_association_not_found")
        default:
          return t("collection_point_adminstration.upload.errors.unknown_error")
      }
    },
    [t],
  )

  return (
    <Grid container item direction="column" spacing={2}>
      <InfoDialog
        open={validationErrors.length > 0 && !confirmedErrors}
        onClose={() => setConfirmedErrors(true)}
        title={t("collection_point_administration.upload.upload_not_possible")}
        content={getErrorMessage(validationErrors[0]?.error)}
      />

      <Backdrop open={validationLoading || uploadLoading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>

      {collectionPoints.length === 0 && (
        <>
          <Grid container item direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h5">
                {`${regionName.toUpperCase()}  > `}
                <b>{t("collection_point_administration.upload.upload_collection_points")}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Dropzone
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                text={t("collection_point_administration.upload.dropzone_text")}
                underline={
                  <Typography className={classes.templateLabel}>
                    <Trans
                      t={t}
                      i18nKey={"collection_point_administration.upload.dropzone_underline_text"}
                      components={[
                        <Typography
                          onClick={() => downloadTemplateFile()}
                          component={"span"}
                          className={`${classes.templateLabel} ${classes.templateLink} ${
                            downloadTemplateFileLoading ? classes.templateLinkLoading : ""
                          }`}
                        />,
                      ]}
                    />
                  </Typography>
                }
                updateFiles={onFileDropped}
                files={files}
                className={classes.dropzone}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Button variant="contained" type="button" color="primary" onClick={() => history.goBack()}>
              {t("collection_point_administration.upload.back")}
            </Button>
          </Grid>
        </>
      )}
      {collectionPoints.length !== 0 && validationErrors.length === 0 && <UploadCollectionPointsTable />}
    </Grid>
  )
}
